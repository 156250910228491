

.VisibleHeatTest {
	width: 56.25vh;
	height: 100vh;
	background-position: center;
	background-size: cover;
	box-sizing: border-box;
	position: relative;
	padding-right: 2vh;
	text-align: right;
	overflow: hidden;


	&-Hide {
		position: absolute;
		opacity: 0;
		top: -200vh;

		img {
			height: 0;
			width: 0;
			user-select: none;
		}
	}

	&-Heading {
		font-family: 'NeueHelveticaHOI';
		// text-overflow: clip;
		// overflow-wrap: break-word;
		font-size: 11vh;
		text-align: right;
		width: 100%;
		float: right;
		max-width: 49vh;
		text-transform: uppercase;
	}

	&-SubTitle {
		font-size: 2vh;
		text-align: right;
		padding-top: 18vh;
		margin-bottom: 4vh;
	}

	&-Description {
		left: 5vh;
		position: absolute;
		bottom: 6vh;
		font-size: .8vh;
		text-align: left;
		max-width: 22vh;
		position: absolute;

		div {
			line-height: 200%;
			transform: rotate(-90deg);
		}
	}
}
